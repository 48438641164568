import axios from 'axios';
import qs from 'qs';
import { jsonParse, getCookie, TokenKey } from '../utils';
import { baseUrl } from '../config/env';
// 这个文件不用引用sentry
const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
const DATA_PARAM_PREFIX = 'PostData'; // 请求统一前缀管理
// const PLUGIN_PARAM_PREFIX = 'ParamDict';
axios.defaults.baseURL = baseUrl;
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
// 请求拦截器
axios.interceptors.request.use((config) => {
    const data = config.data;
    config.headers.TraceId = guid();
    const token = getCookie(TokenKey);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    // 添加请求参数
    if (data) {
        config.data = qs.stringify(data);
    }
    return config;
}, error => {
    Promise.reject(error);
});
// 响应拦截器
axios.interceptors.response.use((response) => {
    let responseData = response.data;
    if (typeof responseData === 'string') {
        return responseData;
    }
    if (typeof responseData !== 'object') {
        responseData = jsonParse(responseData);
        response.data = responseData;
    }
    return response.data;
}, (error) => {
    if (error.response) {
        switch (error.response.status) {
            case 401:
                console.log(401);
                break;
            default:
                break;
        }
    }
    return error;
});
export default function fetch(...args) {
    const defaultReturnData = {
        Successful: false,
        ReturnData: {}
    };
    let requestConfig;
    let allowAnonymous = false;
    if (args.length === 0) {
        return Promise.resolve(defaultReturnData);
    }
    else if (typeof args[0] === 'object') {
        requestConfig = args[0];
        allowAnonymous = !!args[1];
    }
    else {
        const [url, data, method = 'Get', allowAnonymousTemp] = args;
        allowAnonymous = allowAnonymousTemp;
        let realUrl = url;
        if (method.toUpperCase() === 'GET') {
            if (Object.keys(data).length) {
                const char = realUrl.indexOf('?') > -1 ? '&' : '?';
                realUrl = `${realUrl}${char}${qs.stringify(data)}`;
            }
            // if (data[DATA_PARAM_PREFIX] !== undefined) {
            //   realUrl = `${url}?${DATA_PARAM_PREFIX}=${data[DATA_PARAM_PREFIX]}`;
            // }
        }
        // console.log('data', data);
        requestConfig = {
            url: realUrl,
            data,
            method: method.toUpperCase()
        };
    }
    return new Promise((resolve) => {
        axios.request(requestConfig).then((response) => {
            const responseData = response;
            if (!responseData) {
                resolve(defaultReturnData);
            }
            resolve(responseData);
            return responseData;
        }).catch((error) => {
            resolve(error);
            return error;
        });
    });
}
export { axios };
