class FakeLocalStorage {
    cache = {};
    setItem(key, value) {
        this.cache[key] = value;
    }
    getItem(key) {
        return this.cache[key];
    }
    removeItem(key) {
        delete this.cache[key];
    }
}
function storgeValueEncrypt(value) {
    return JSON.stringify({ content: value });
}
function storgeValueDecrypt(value) {
    return JSON.parse(value).content;
}
export class GlobalStorage {
    cache = {};
    config = {
        devtool: true,
        localNamespace: ''
    };
    browserLocalStorage;
    constructor() {
        this.browserLocalStorage = window.localStorage;
    }
    /**
     * 存储 全局实例 数据等
     * @memberof GlobalStorage
     */
    set(key, value) {
        this.cache[key] = value;
    }
    /**
     * 获取存储 全局实例
     * @memberof GlobalStorage
     */
    get(key) {
        return this.cache[key];
    }
    /**
     * 删除 全局实例
     * @memberof GlobalStorage
     */
    remove(key) {
        delete this.cache[key];
    }
    /**
     * 获取 所有存储的实例
     * @memberof GlobalStorage
     */
    list() {
        return { ...this.cache };
    }
    setNamespace(namespace) {
        this.config.localNamespace = namespace;
    }
    handleLocalKey(key) {
        return this.config.localNamespace ? [this.config.localNamespace, key].join('.') : key;
    }
    /**
     * 设置到 localStorage
     * @param key String 键
     * @param value 值
     * @param options
     * options: {
     *
     *    expired?: number; 缓存时长，单位：ms
     *
     *    compile?: boolean; 加密
     *
     * }
     */
    setToLocal(key, value, options) {
        const { expired, compile } = options || {};
        const _value = compile ? storgeValueEncrypt(value) : value;
        const data = {
            value: _value,
            writeTime: Date.now(),
            expired,
            compile
        };
        this.browserLocalStorage.setItem(this.handleLocalKey(key), JSON.stringify(data));
    }
    /**
     * 从 localStorage 获取
     * @param key 键
     */
    getFormLocal(key) {
        const dataJSON = this.browserLocalStorage.getItem(this.handleLocalKey(key));
        // 当目标不存在时直接结束
        if (this.isNotExist(dataJSON)) {
            return null;
        }
        // 这里解析token报错 要抛出
        try {
            const data = JSON.parse(dataJSON);
            // 当数据的缓存时长未定义时，它被认为是永久的
            if (this.isNotExist(data.expired)) {
                return data.compile ? storgeValueDecrypt(data.value) : data.value;
            }
            // 数据缓存时长结束时释放数据
            if (this.isOutPeriod(data)) {
                this.removeFormLocal(key);
                return null;
            }
            return data.compile ? storgeValueDecrypt(data.value) : data.value;
        }
        catch (error) {
            return false;
        }
    }
    /**
     * 从 localStorage 删除
     * @param key 键
     */
    removeFormLocal(key) {
        this.browserLocalStorage.removeItem(this.handleLocalKey(key));
    }
    /**
     * isOutPeriod 方法，判断 value 值是否过期
     * @param value 值
     */
    isOutPeriod(value) {
        if (!value.expired) {
            return false;
        }
        const readTime = Date.now();
        return (readTime - value.writeTime) > value.expired;
    }
    isNotExist(value) {
        return value === null || typeof value === 'undefined';
    }
}
