import Cookies from 'js-cookie';
export const isDingtalk = /DingTalk/.test(navigator.userAgent);
export const TokenKey = 'h3_token';
export const ShardKey = 'shardkey2';
export const TokenCorpKey = 'token_corp';
export const EngineCodeKey = 'enginecode';
export const WechatCodeKey = 'wechatcode';
export const CacheConfigKey = 'cache_config';
export const H3SessionKey = 'h3_Session';
export const H3EngineCode = 'h3_EngineCode';
export const UpStreamIp = 'upstreamip3';
export const RefreshSeconds = 4 * 60 * 60;
export const AutoLoginKey = 'auto_login';
export const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
// 是否微信终端(包括微信、企业微信)
export const isWechat = !!navigator.userAgent.match(/MicroMessenger/i);
// 企业微信(包括移动端、PC工作台)
export const isWxwork = /wxwork/i.test(navigator.userAgent);
// 是否微信浏览器(企业微信)
export const isWechatBrowser = isWechat && !isWxwork;
// 是否是app进来的
export const isAppRuningEnv = (localStorage.getItem("runningEnv") === "app" || navigator.userAgent.match(/H3YunApp/i)) ? true : false;
export const parseToken = (token) => {
    if (!token) {
        return {};
    }
    const array = token.split('.');
    if (array.length !== 3) {
        return {};
    }
    try {
        const bussiContentBase64 = array[1];
        const bussiContentJsonString = atob(bussiContentBase64);
        return JSON.parse(bussiContentJsonString);
    }
    catch (error) {
        console.error(error);
    }
    return {};
};
export const parseUrlParams = (url) => {
    const a = document.createElement('a');
    a.href = url;
    const ret = {};
    const search = a.search;
    let seg;
    let s;
    if (!search) { // 修复存在hash时search失效的问题
        const tmpArr = a.href.split('?');
        if (tmpArr.length === 1) {
            return {};
        }
        seg = tmpArr[tmpArr.length - 1].split('&');
    }
    else {
        seg = search.replace(/^\?/, '').split('&');
    }
    for (let i = 0, len = seg.length; i < len; i++) {
        if (!seg[i]) {
            continue;
        }
        s = seg[i].split('=');
        if (s[0] === 'cid') { // hack 针对酷应用cid特殊处理
            const cid = seg[i].split('cid=')[1];
            ret.cid = cid;
        }
        else {
            ret[s[0]] = s[1];
        }
    }
    return ret;
};
/**
 * Add the object as a parameter to the URL
 * @param baseUrl url
 * @param obj
 * @returns {string}
 * eg:
 *  let obj = {a: '3', b: '4'}
 *  setObjToUrlParams('www.baidu.com', obj)
 *  ==>www.baidu.com?a=3&b=4
 */
export function setObjToUrlParams(baseUrl, obj) {
    let parameters = '';
    for (const key in obj) {
        parameters += key + '=' + encodeURIComponent(obj[key]) + '&';
    }
    parameters = parameters.replace(/&$/, '');
    return /\?$/.test(baseUrl) ? baseUrl + parameters : baseUrl.replace(/\/?$/, '?') + parameters;
}
export const fetchHandler = (ret, callback) => {
    if (ret.errcode) {
        // 如果是切换企业 直接跳转错误页
        const engineCode = sessionStorage.getItem("engineCode");
        if (engineCode) {
            location.href = `${window.location.origin}/error.html?errMessage=${ret.errmsg}`;
        }
        else {
            alert(ret.errmsg);
            // 企微返回上一页
            if (window.sessionStorage.getItem('scanType') === 'wxwork') {
                history.go(-1);
            }
            return;
        }
    }
    ret.errcode === 0 && callback(ret);
};
export function getCookie(key) {
    return Cookies.get(key);
}
export function setCookie(name, value, expireDays, path = '/') {
    const date = new Date();
    date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
    Cookies.set(name, value, {
        expires: date,
        path: path,
    });
}
export function setCookie2(name, value, hours = 8) {
    Cookies.remove(name);
    const d = new Date();
    d.setTime(d.getTime() + hours * 60 * 60 * 1000);
    Cookies.set(name, value, {
        expires: d,
    });
}
export function removeCookie2(name) {
    Cookies.remove(name);
}
export function setAuthorization(token, shardKey, corpId = '', hours = 8) {
    // 钉钉pc端切换企业时需要判断token是否当前corpid，如果不是，则需要重新登录
    corpId && setCookie2(TokenCorpKey, corpId, hours);
    setCookie2(TokenKey, token, hours);
    if (shardKey) {
        setCookie2(ShardKey, shardKey, hours);
    }
}
export function setAuthCookie(ret, corpId, hours = 8) {
    const { token, shardKey, enginecode } = ret.result;
    setCookie2(TokenCorpKey, corpId || '', hours);
    setCookie2(TokenKey, token || '', hours);
    setCookie2(ShardKey, shardKey || '', hours);
    setCookie2(H3EngineCode, enginecode || '', hours);
}
export function clearAuthorization() {
    setCookie2(TokenCorpKey, '', -1);
    setCookie2(TokenKey, '', -1);
    setCookie2(ShardKey, '', -1);
    setCookie2(H3EngineCode, '', -1);
}
export function clearCompatibile() {
    setCookie2(H3SessionKey, '', -1);
    setCookie2(H3EngineCode, '', -1);
}
export const jsonParse = (jsonString) => {
    if (!jsonString) {
        return false;
    }
    try {
        return JSON.parse(jsonString);
    }
    catch (err) {
        return false;
    }
};
const loading = document.getElementById('loading'); // 引导容器
export const showLoading = (flag) => {
    if (loading) {
        loading.style.display = flag ? 'block' : 'none';
    }
};
export const loginJump = (token) => {
    const fromPath = window.sessionStorage.getItem('origin_fromPath') || '';
    if (fromPath === 'associatedOrganization') {
        // 互通组织
        const enginecode = window.sessionStorage.getItem('associatedOrg_enginecode') || '';
        const guid = window.sessionStorage.getItem('associatedOrg_guid') || '';
        window.location.href = `${window.location.origin}/scb/index.html#/associated-organization-entry?guid=${guid}&enginecode=${enginecode}&t=${new Date().getTime()}`;
        // window.location.href = `${window.location.origin}${window.location.pathname}index.html#/associated-organization-entry?guid=${guid}&enginecode=${enginecode}&t=${new Date().getTime()}`;
        return true;
    }
    else if (fromPath === 'templateCenter') {
        // 模板中心
        let url = window.sessionStorage.getItem('templateCenter_redirect') || '';
        url = window.decodeURIComponent(url);
        if (url.indexOf('?') > -1) {
            window.location.href = `${url}&h3_token=${token}`;
        }
        else {
            window.location.href = `${url}?h3_token=${token}`;
        }
        return true;
    }
    return false;
};
export function trim(string, global = 'g') {
    let result = string.replace(/(^\s+)|(\s+$)/g, '');
    if (global.toLowerCase() === 'g') {
        result = result.replace(/\s/g, '');
    }
    return result;
}
// 跳转首页
export function jumpIndexUrl(enginecode = '') {
    window.location.replace(`${window.location.origin}/index.html?t=${new Date().getTime()}${enginecode ? `&enginecode=${enginecode}` : ``}`);
}
