import fetch from "@/lib/fetch";
import { getCookie, H3EngineCode } from '@/utils';
var Api;
(function (Api) {
    // 获取登录验证码
    Api["SendLoginVerifyCodeOld"] = "/loginapi/v1/h3/mobile/verifycode";
    Api["SendLoginVerifyCode"] = "/loginapi/v2/h3/mobile/verifycode";
    // 手机号密码登录
    Api["LoginByPasswordWechat"] = "/loginapi/v1/tokens/jwt/h3/password";
    Api["LoginByPassword"] = "/loginapi/v2/tokens/jwt/h3/password";
    // 验证码登录
    Api["LoginByCode"] = "/loginapi/v1/tokens/jwt/h3/mobile";
    // 选择一个企业登录
    Api["LoginByCorp"] = "/loginapi/v1/tokens/jwt/h3/corps/unauthentication";
    // 获取注册验证码
    // SendRegisterVerifyCode = "/register/v1/h3/mobile/verifycode",
    Api["SendRegisterVerifyCode"] = "/register/v2/h3/mobile/verifycode";
    // 验证注册验证码
    Api["ValidateRegisterVerifyCode"] = "/register/v1/h3/mobile/verifycode/validater";
    // 注册企业
    Api["RegisterWithCorp"] = "/register/v1/h3/mobile";
    // 获取企业人员规模
    Api["GetScaletypeList"] = "/register/v1/h3/getlist?typeName=ScaleType";
    // 续期token
    Api["RenewalToken"] = "/loginapi/v1/tokens/jwt/h3/token";
    // 外部用户手机号验证码登录
    Api["ExternalLogin"] = "/loginapi/v1/tokens/jwt/h3/externalmobile";
    // 扫码登录-钉钉
    Api["LoginByDingScan"] = "/loginapi/v1/tokens/jwt/dingtalk/scan";
    // 扫码登录-企业微信
    Api["LoginByWxworkScan"] = "/loginapi/v1/tokens/jwt/wechat/suite/scan";
    // 获取钉钉扫码链接
    Api["GetDingScanUrl"] = "/loginapi/v1/tokens/jwt/dingtalk/scanurl";
    // 获取企业微信扫码链接
    Api["GetWxworkScanUrl"] = "/loginapi/v1/tokens/jwt/wechat/scanurl";
    // 扫码登录-组织互通
    Api["ScanLoginByAssOrg"] = "/loginapi/v1/tokens/jwt/dingtalk/dingtalkfreethirdparty";
    // 重置密码
    Api["ResetPassword"] = "/loginapi/v1/h3/password";
    // 获取注册信息
    Api["GetRegisterInfo"] = "/register/v1/h3/register/initinfo";
    // 钉钉后台登录
    Api["DdOaAuth"] = "/loginapi/v1/tokens/jwt/dingtalk/dingtalkoa";
    // 微信扫码登录
    Api["WechatOaAuth"] = "/loginapi/v1/tokens/jwt/wechat/official";
    // 匿名登录
    Api["AnonymousLogin"] = "/loginapi/v1/tokens/jwt/h3/corps/anonymous";
    // 获取应用和解决方案相关信息
    Api["GetAppInfo"] = "/App/IsLightAndIsBetaForm";
    // 获取ticket信息(微信)
    Api["GetWechatTicketInfo"] = "/wechatofficial/getTicket";
    // 获取扫码状态信息(微信)
    Api["GetWechatScanStatus"] = "/wechatofficial/checkScan";
    // 钉钉企业接入，钉钉客户端登录
    Api["DdCropLogin"] = "/loginapi/v1/tokens/jwt/dingtalk/dingtalkappcorp";
    // 钉钉免登授权
    Api["DdAuth"] = "/loginapi/v1/tokens/jwt/dingtalk/app";
    // 校验发起互通的组织状态
    Api["VerifyAssOrg"] = "/LoginWebAPI/VerifyAssociatedOrganization";
    // 获取登录接口无痕验证配置
    // GetLoginVerifyConfig = "/loginapi/v1/h3/verify/config",
    Api["GetLoginVerifyConfig"] = "/loginapi/v2/h3/verify/config";
    // 获取注册接口无痕验证配置
    // GetRegisterVerifyConfig = "/register/v1/h3/verify/config",
    Api["GetRegisterVerifyConfig"] = "/register/v2/h3/verify/config";
    // 确认互通
    Api["MakeAssociated"] = "/AssociatedOrganization/OnAction";
    // 是否付费
    Api["IsPayUser"] = "/Prompt/OnAction";
    // 切换外部组织
    Api["ChangeExternalOrg"] = "/loginapi/v1/tokens/jwt/h3/associatedorganization";
    // 数据埋点
    Api["EventRecordLog"] = "/h3/v1/eventLog/createEventLog";
})(Api || (Api = {}));
// 登录逻辑
// 获取登录验证码
export const sendLoginVerifyCode = (params) => {
    return fetch(Api.SendLoginVerifyCode, params, "POST");
};
// 获取登录验证码（重置密码）
export const sendLoginVerifyCodeOld = (params) => {
    return fetch(Api.SendLoginVerifyCodeOld, params, "POST");
};
// 手机号密码登录
export const loginByPassword = (params) => {
    return fetch(Api.LoginByPassword, params, "POST");
};
// 手机号密码登录（绑定微信）
export const loginByPasswordWechat = (params) => {
    return fetch(Api.LoginByPasswordWechat, params, "POST");
};
// 验证码登录
export const loginByCode = (params) => {
    return fetch(Api.LoginByCode, params, "GET");
};
// 选择一个企业登录
export const loginByCorp = (params) => {
    return fetch(Api.LoginByCorp, params, "GET");
};
// 注册企业
// 获取注册验证码
export const sendRegisterVerifyCode = (params) => {
    return fetch(Api.SendRegisterVerifyCode, params, "POST");
};
// 验证注册验证码
export const validateRegisterVerifyCode = (params) => {
    return fetch(Api.ValidateRegisterVerifyCode, params, "POST");
};
// 注册企业
export const registerWithCorp = (params) => {
    return fetch(Api.RegisterWithCorp, params, "POST");
};
// 获取企业人员规模
export const getScaletypeList = () => {
    return fetch(Api.GetScaletypeList, null, "POST");
};
// 续期token
export const renewalToken = (params) => {
    return fetch(Api.RenewalToken, params, "GET");
};
// 外部用户手机号验证码登录
export const externalLogin = (params) => {
    return fetch(Api.ExternalLogin, params, "GET");
};
// 扫码登录-组织互通
export const scanLoginByAssOrg = (params) => {
    return fetch(Api.ScanLoginByAssOrg, params, "POST");
};
// 扫码登录-钉钉
export const loginByDingScan = (params) => {
    return fetch(Api.LoginByDingScan, params, "GET");
};
// 扫码登录-企业微信
export const loginByWxworkScan = (params) => {
    return fetch(Api.LoginByWxworkScan, params, "GET");
};
// 获取钉钉扫码链接
export const getDingScanUrl = () => {
    return fetch(Api.GetDingScanUrl, {}, "GET");
};
// 获取企业微信扫码链接
export const getWxworkScanUrl = (params) => {
    return fetch(Api.GetWxworkScanUrl, params, "GET");
};
// 密码找回
// 重置密码
export const resetPassword = (params) => {
    return fetch(Api.ResetPassword, params, "PUT");
};
// 获取注册信息
export const getRegisterInfo = () => {
    return fetch(Api.GetRegisterInfo, null, "POST");
};
// 钉钉后台登录
export const ddOaAuth = (params) => {
    return fetch(Api.DdOaAuth, params, "GET");
};
// 微信扫码登录
export const wechatOaAuth = (params) => {
    return fetch(Api.WechatOaAuth, params, "POST");
};
// 匿名登录
export const anonymousLogin = (params) => {
    return fetch(Api.AnonymousLogin, params, "GET");
};
// 获取应用和解决方案相关信息
export const getAppInfo = (params) => {
    return fetch(Api.GetAppInfo, { ...params, ddtab: true }, "GET");
};
// 获取ticket信息(微信)
export const getWechatTicketInfo = () => {
    return fetch(Api.GetWechatTicketInfo, {}, "GET");
};
// 获取扫码状态信息(微信)
export const getWechatScanStatus = (params) => {
    return fetch(Api.GetWechatScanStatus, params, "GET");
};
// 钉钉企业接入，钉钉客户端登录
export const ddCropLogin = (params) => {
    return fetch(Api.DdCropLogin, params, "POST");
};
// 钉钉免登授权
export const ddAuth = (params) => {
    return fetch(Api.DdAuth, params, "GET");
};
// 校验发起互通的组织状态
export const verifyAssOrg = (params) => {
    return fetch(Api.VerifyAssOrg, params, "POST");
};
// 获取登录接口无痕验证配置
export const getLoginVerifyConfig = () => {
    return fetch(Api.GetLoginVerifyConfig, {}, "GET");
};
// 获取注册接口无痕验证配置
export const getRegisterVerifyConfig = () => {
    return fetch(Api.GetRegisterVerifyConfig, {}, "GET");
};
// 确认互通
export const makeAssociated = (params) => {
    return fetch({ url: Api.MakeAssociated, data: { PostData: JSON.stringify({ ...params, ActionName: "MakeConnection" }) }, method: 'POST', headers: { EngineCode: getCookie(H3EngineCode) } });
};
// 是否付费
export const isPayUser = () => {
    return fetch(Api.IsPayUser, { PostData: JSON.stringify({ ActionName: "GetIsPaid" }) }, "POST");
};
// 切换外部组织
export const ChangeExternalOrg = (code) => {
    return fetch(Api.ChangeExternalOrg, { code }, 'GET');
};
// 数据埋点
export const EventRecordLog = (appCode = '', schemaCode = '', eventCode, solutionCode = '', isMobile = false) => {
    const params = {
        AppCode: appCode,
        SchemaCode: schemaCode,
        EventCode: eventCode,
        SolutionCode: solutionCode,
        IsMobile: isMobile,
    };
    return fetch(Api.EventRecordLog, params, 'POST');
};
