/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 域名地址
 * routerMode: 路由模式
 * imgBaseUrl: 图片所在域名地址
 *
 */
// const path = require('path');
const routerMode = 'history';
let IsDevMode = false;
let baseUrl;
if (process.env.NODE_ENV === 'production') {
    baseUrl = '';
}
else {
    IsDevMode = true;
    const localDevGroup = window.localStorage.getItem('H3_DEV_GROUP') || '';
    baseUrl = localDevGroup ? `/${localDevGroup}Api` : '/apis';
}
if (window.debugUrl || window.debugUrl === '') {
    baseUrl = '';
}
const currentUrl = window.location.origin;
const isDingtalk = /DingTalk/.test(navigator.userAgent) || /DingTalk/.test(window.top.navigator.userAgent);
const resourceUrl = process.env.RESOURCE_URL;
export { baseUrl, routerMode, currentUrl, isDingtalk, IsDevMode, resourceUrl };
