import { getCookie, isDingtalk, isMobile, setAuthCookie, clearAuthorization, TokenKey, TokenCorpKey, ShardKey, H3EngineCode, showLoading, setCookie2, UpStreamIp, parseToken, RefreshSeconds } from '../utils';
import { parseUrl } from '../utils/url-helper';
import { getRouterUrl } from '../utils/route-helper';
import { ddLogin, ddOaLogin } from '../utils/dd-auth';
import { renewalToken, ChangeExternalOrg } from '../apis';
import { openLink, closeQuit, ddAlert } from '../lib/dingtalk';
import { wxworkIsOnPc, ttIsOnPc } from '../../modules/wxwork/utils/navigator';
// 重定向的类型 1. 首页， 2. 应用， 3. 待办， 4. Task
const getRedirectPath = async (config) => {
    const retUrl = await getRouterUrl(config);
    const isWxwork = wxworkIsOnPc();
    let search = '';
    const nowTime = new Date().getTime();
    if (isWxwork) {
        if (config.wechatisvcorpid) {
            search += `&wechatisvcorpid=${config.wechatisvcorpid ? config.wechatisvcorpid : ''}&wechatsuiteid=${config.wechatsuiteid ? config.wechatsuiteid : ''}&agentid=${config.agentId ? config.agentId : ''}`;
        }
        else {
            search += `&wechatcorpid=${config.corpId ? config.corpId : ''}&agentid=${config.agentId ? config.agentId : ''}&wechatsuiteid=${config.wechatsuiteid ? config.wechatsuiteid : ''}`;
        }
    }
    if (ttIsOnPc) {
        search += `&feishucorpid=${config.corpId}&agentid=${config.agentId}`;
    }
    if (window.location.href.indexOf('login-ddcorp') > -1) {
        search += `&isDingtalkCorp=true&agentid=${config.agentId}`;
    }
    if (retUrl.indexOf('?') > -1) {
        return `${retUrl}&enginecode=${getCookie(H3EngineCode)}&${ShardKey}=${getCookie(ShardKey)}${search}&t=${nowTime}`;
    }
    return `${retUrl}?enginecode=${getCookie(H3EngineCode)}&${ShardKey}=${getCookie(ShardKey)}${search}&t=${nowTime}`;
};
// 切换外部组织
const switchExternalOrg = async (config) => {
    const res = await ChangeExternalOrg(config.associatedCode);
    const { errmsg } = res;
    if (errmsg) {
        alert(errmsg);
    }
    else {
        setCookie2(UpStreamIp, "", -1);
        setAuthCookie(res, config.corpId);
    }
};
export const redirect = async (config) => {
    const redirectUrl = await getRedirectPath(config);
    // 外部组织流程通知
    if (config.associatedCode) {
        await switchExternalOrg(config);
    }
    let url = `${window.location.origin}${redirectUrl}`;
    showLoading(false);
    const tmp = new Date().getTime() * 1;
    // ddtab 签页标识
    if (config.ddtab) {
        if (config.worknotify && config.dingtalkAppId) {
            const index = url.indexOf('#'); // 这个判断需要再 细化一下
            if (index > -1) {
                // url = url.substr(0, index) + `?t=${tmp}&ddtab=true&corpid=${config.corpId}&${TokenKey}=${getCookie(TokenKey)}` + url.substr(index);
                url = url.substr(0, index) + `?t=${tmp}&ddtab=true&corpid=${config.corpId}` + url.substr(index);
                openLink(url);
                setTimeout(closeQuit, 100);
                return;
            }
        }
        else {
            url = url.replace(`&ddtab=${config.ddtab}`, '');
        }
    }
    // 判断是否是消息，如果是消息，则在浏览器中打开
    if (isDingtalk && config.messageType) {
        const msgUrl = `${window.location.origin}/scb?token=${getCookie(TokenKey)}&shardkey=${getCookie(ShardKey)}&redirecturl=${encodeURIComponent(url)}&messageType=${config.messageType}`;
        openLink(msgUrl);
        return;
    }
    // 添加from参数标识登录入口
    if (config.from) {
        url = url + `&from=${config.from}`;
        window.localStorage.setItem('h3yun_open_type', config.from);
    }
    else {
        window.localStorage.setItem('h3yun_open_type', 'Home');
    }
    window.location.replace(url);
};
export const setAidCookie = (config) => {
    if (config.suiteKey) {
        setCookie2('h3_SuiteKey', config.suiteKey);
    }
    if (config.solutionCode) {
        setCookie2('h3_SolutionCode', config.solutionCode);
    }
};
export const dingtalkMobileRedirect = () => {
    const url = `${window.location.origin}/index.html${window.location.search}&t=${new Date().getTime()}&isLogin=1`;
    window.location.replace(url);
};
// 登录入口
const oAuth = async () => {
    const config = parseUrl(window.location.href);
    // console.log('AuthConfig: ', config);
    window.globalConfig = config;
    // 企业集成钉钉入口
    if (window.location.hash.indexOf('#/login-ddcorp') > -1) {
        return true;
    }
    // 模板中心
    if (window.location.hash.indexOf('fromPath=templateCenter') > -1) {
        return true;
    }
    if (config.logininvalid === 'true') { // token失效时同时没法自动授权的场景，需要抛出错误提示(目前存在的是企微和公众号)
        alert("登录态失效，请重新访问氚云");
        return false;
    }
    if (isMobile) {
        if ((window.location.hash.indexOf('#/associated-organization') > -1 || window.location.hash.indexOf('#/login-mobile') > -1 || window.location.hash.indexOf('#/auth-mobile') > -1)) {
            return true;
        }
        const filterUrl = [
            `${window.location.origin}/scb/index.html#/login-pass`,
            `${window.location.origin}/scb/#/login-pass`,
            `${window.location.origin}/scb/index.html#/login-pass?type=ding`,
            `${window.location.origin}/scb/#/login-pass?type=ding`,
            `${window.location.origin}/scb/index.html#/login-pass?type=phone`,
            `${window.location.origin}/scb/#/login-pass?type=phone`,
            `${window.location.origin}/scb/index.html#/reset-password`,
            `${window.location.origin}/scb/#/reset-password`,
            `${window.location.origin}/scb/register.html#/register?type=ding`,
            `${window.location.origin}/scb/register.html#/register?type=phone`
        ];
        if (filterUrl.includes(window.location.href)) { // 移动端访问 钉钉/手机号登录、钉钉/手机号注册、忘记密码页面 提示不让直接进入 https://authine.yuque.com/docs/share/ff7428ea-eac9-4187-aec0-b7c420e064ed?#gQuz
            const redirectUrl = encodeURIComponent(window.location.href);
            window.location.replace(`${window.location.origin}/scb/common.html#/jump-tips?redirect=${redirectUrl}`);
            return false;
        }
    }
    const jwt = getCookie(TokenKey);
    const tokenCorp = getCookie(TokenCorpKey);
    let ret;
    if (config.corpId) { // 把corpId缓存下来，后面在登录失效的时候需要用到
        localStorage.setItem('corpIdKey', config.corpId);
    }
    else {
        localStorage.removeItem('corpIdKey');
    }
    if (!isDingtalk) {
        if (config.code && !config.state) {
            // 钉钉后台登录, 区分扫码
            clearAuthorization();
            ret = await ddOaLogin(config);
            if (ret && !ret.errcode) {
                setAuthCookie(ret, config.corpId);
                let url = `${window.location.origin}/home.html?t=${new Date().getTime()}`;
                if (config.from) {
                    url += `&from=${config.from}`;
                    window.localStorage.setItem('h3yun_open_type', config.from);
                }
                else {
                    window.localStorage.setItem('h3yun_open_type', 'Home');
                }
                window.location.replace(url);
                return false;
            }
        }
        return true;
    }
    // 年度大数据，提示不能在pc端打开
    if (config.messageType === 'AnnualData') {
        return true;
    }
    !isMobile && showLoading(true);
    const jwtObj = parseToken(jwt);
    const exp = jwtObj.exp;
    const associatedmainenginecode = jwtObj.associatedmainenginecode;
    const nowTimestamp = Math.round(Number(new Date()) / 1000);
    const isExceedTime = exp ? exp > nowTimestamp : false; // exp存在的同时，如果token的过期时间已经小于当前时间，即需要重新授权，不能进行token续期
    if (jwt && tokenCorp && config.corpId === tokenCorp && isExceedTime && !associatedmainenginecode) {
        // 在redirect时，如果token剩余时间小于4小时刷新token
        if (exp) {
            const needRefresh = (exp - nowTimestamp) < RefreshSeconds;
            if (needRefresh) {
                ret = await renewalToken({ token: jwt });
                if (ret) {
                    if (ret.errcode !== 0) {
                        console.warn('renewalToken ' + ret.errmsg);
                        clearAuthorization(); // 清除token相关
                        alert('renewalToken:' + ret.errmsg);
                        return false;
                    }
                    else {
                        setAuthCookie(ret, config.corpId); // 续期成功，刷新cookie
                    }
                }
            }
        }
        // 缓存suitekey和solutioncode h3_SuiteKey h3_SolutionCode
        setAidCookie(config);
        if (isMobile) {
            dingtalkMobileRedirect();
        }
        else {
            redirect(config);
        }
    }
    else {
        clearAuthorization();
        // 这个地方需要加引导动画
        ret = await ddLogin(config);
        if (!ret.errcode) {
            setAuthCookie(ret, config.corpId);
            // 缓存suitekey和solutioncode h3_SuiteKey h3_SolutionCode
            setAidCookie(config);
            if (isMobile) {
                dingtalkMobileRedirect();
            }
            else {
                redirect(config);
            }
        }
        else {
            // 根据错误信息进行友好提示
            showLoading(false);
            if (isDingtalk) {
                ddAlert('提示', ret.errmsg);
            }
            else {
                alert(ret.errmsg);
            }
        }
    }
    return false;
};
export default oAuth;
