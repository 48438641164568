import { isDingtalk } from "../utils";
import dd from "dingtalk-jsapi";
/**
 *获取免登授权码
 */
export const requestAuthCode = (corpId, cb, errorCb) => {
    dd.runtime.permission
        .requestAuthCode({
        corpId
    })
        .then((result) => {
        cb(result.code);
    })
        .catch((err) => {
        let msg = "获取免登授权码失败";
        for (const key in err) {
            if (Object.prototype.hasOwnProperty.call(err, key)) {
                msg += `key:${err[key]};`;
            }
        }
        if (typeof errorCb === "function") {
            errorCb(err);
        }
        alert(`${msg} corpId值是${corpId}; 当前url: ${window.location.href}`);
    });
};
export const openLink = (url, type = "_blank") => {
    if (isDingtalk) {
        // PC端调用时，调用此接口跳转到外部浏览器打开目标页面。
        // 手机端调用时，调用此接口由钉钉客户端内置浏览器打开目标页面。
        dd.biz.util.openLink({
            url: url
        });
    }
    else {
        window.open(url, type);
    }
};
/**
 * 关闭应用
 */
export const closeApp = () => {
    if (isDingtalk) {
        dd.biz.navigation.close({
            onSuccess(result) { },
            onFail(err) {
                /* eslint handle-callback-err: "warn" */
                console.log(err);
            }
        });
    }
};
/**
 * 关联侧边栏
*/
export const closeQuit = () => {
    if (isDingtalk) {
        dd.biz.navigation.quit({
            onSuccess(result) { },
            onFail(err) {
                /* eslint handle-callback-err: "warn" */
                console.log(err);
            }
        });
    }
};
/**
 * 钉钉弹窗
*/
export const ddAlert = (title, msg, callback = null) => {
    dd.device.notification.alert({
        message: msg,
        title,
        buttonName: '确认',
        // onSuccess() {
        //   if (typeof (callback) !== 'undefined') {
        //     callback.call(this);
        //   }
        // },
        // onFail() { },
    });
};
