import { GlobalStorage } from './storage';
export const SPA_GLOBAL_STORAGE = Symbol.for('__SPA_GLOBAL_STORAGE__');
const win = window;
const _global = (() => {
    if (typeof window !== 'undefined')
        return window;
    // eslint-disable-next-line no-undef
    if (typeof self !== 'undefined')
        return self;
    throw new Error('no global found');
})();
let _globalStorage;
if (win.SPA_GLOBAL_STORAGE) {
    _globalStorage = win.SPA_GLOBAL_STORAGE;
}
else {
    _globalStorage = new GlobalStorage();
    win[SPA_GLOBAL_STORAGE] = _globalStorage;
}
export const globalStorage = _globalStorage;
