import { parseUrlParams } from './index';
// 全局url参数
const globalConfig = {
    appId: '',
    appCode: '',
    agentId: '',
    corpId: '',
    menuCode: '',
    solutionCode: '',
    suiteKey: '',
    isIsv: '',
    appRankType: '',
    version: '',
    key: '',
    value: '',
    schemaCode: '',
    bizObjectId: '',
    engineCode: '',
    dingtalkAccount: '',
    workitemId: '',
    code: '',
    state: '',
    account: '',
};
// 解析请求参数
export const parseUrl = (url) => {
    const params = parseUrlParams(url);
    for (const paramName of Object.keys(params)) {
        params[paramName.toLowerCase()] = params[paramName];
    }
    if (params.from_dingdingda || params.dingdingda) {
        params.from = 'dingdingda';
    }
    return Object.assign(globalConfig, {
        appId: params.appid,
        appCode: params.appid,
        agentId: params.agentid || params.ai,
        corpId: params.corpid || params.wechatcorpid || params.feishucorpid,
        menuCode: params.menucode,
        solutionCode: params.solutioncode,
        suiteKey: params.suitekey || params.sk,
        isIsv: params.type ? true : (!!params.isIsv),
        appRankType: params.appranktype,
        version: '',
        key: '',
        value: '',
        schemaCode: params.schemacode || params.sc,
        bizObjectId: params.bizobjectid || params.bo,
        engineCode: params.enginecode,
        dingtalkAccount: '',
        workitemId: params.workitemid,
        code: params.code,
        messageType: params.messagetype,
        taskOpenType: params.taskopentype,
        sharingKey: params.sharingkey,
        ddIngPid: params.ddingpid,
        ddIngTid: params.ddingtid,
        state: params.state,
        account: params.account,
        redirectType: params.redirectType,
        redirectUrl: params.redirectUrl,
        ddtab: params.ddtab,
        worknotify: params.worknotify,
        dingtalkAppId: params.dingtalkappid,
        from: params.from,
        cid: params.cid ? params.cid : '',
        logininvalid: params.logininvalid ? params.logininvalid : 'false',
        associatedCode: params.associatedCode ? params.associatedCode : '',
        wechatisvcorpid: params.wechatisvcorpid ? params.wechatisvcorpid : '',
        wechatsuiteid: params.wechatsuiteid ? params.wechatsuiteid : '',
    });
};
