import { ddAuth, ddOaAuth, ddCropLogin } from '../apis';
import { requestAuthCode } from '../lib/dingtalk';
/**
 * 钉钉鉴权 获取授权码
 * @param params 包含随机串/时间戳/签名
 * @param config 常规config
 */
export const ddLogin = (config) => {
    return new Promise((resolve) => {
        requestAuthCode(config.corpId, async (code) => {
            const ret = await ddAuth({
                code,
                corpid: config.corpId,
                suitekey: config.suiteKey || '',
                from: config.from || ''
            });
            resolve(ret);
        });
    });
};
// 钉钉后台直接登录
export const ddOaLogin = async (config) => {
    const ret = await ddOaAuth({ code: config.code || '', suitekey: config.suiteKey || '' });
    return ret;
};
// 企业集成钉钉授权登录
export const ddCorpLogin = (params) => {
    return new Promise((resolve) => {
        requestAuthCode(params.corpId, async (code) => {
            const ret = await ddCropLogin({
                code,
                agentid: params.agentId,
                corpid: params.corpId
            });
            resolve(ret);
        });
    });
};
