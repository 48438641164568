// 是否飞书
export const isTT = !!(navigator.userAgent.indexOf("Feishu") > -1 || navigator.userAgent.toLowerCase().match(/\sTTWebView/i) || window.tt);
// 飞书打开终端
export const ttIsOnPc = isTT && (isWindowsNt() || isMac());
export function isWindowsNt() {
    const reg = /Windows NT/i;
    return reg.test(navigator.userAgent);
}
export function isMac() {
    const reg = /Macintosh; Intel Mac OS/i;
    return reg.test(navigator.userAgent);
}
// 企业微信
export function isWxwork() {
    const reg = /wxwork/i;
    return reg.test(navigator.userAgent);
}
/**
 * 企业微信打开终端
 *
 * @export
 * @returns
 */
export function wxworkIsOnPc() {
    const isPc = isWxwork() && (isWindowsNt() || isMac());
    return isPc;
}
