import { getAppInfo } from '../apis/index';
// 发布到钉钉工作台的应用打开类型
const EntryOpenType = {
    App: 'App',
    Solution: 'Solution',
    Report: 'Report',
    Sheet: 'Sheet',
    List: 'List',
    WorkFlowDesigner: 'WorkFlowDesigner',
    Designer: 'Designer',
    DingTalkWorkRecord: 'DingTalkWorkRecord',
    DashBoard: 'DashBoard'
};
// 表单打开类型
var FormType;
(function (FormType) {
    FormType[FormType["Detail"] = 1] = "Detail";
    FormType[FormType["Share"] = 2] = "Share";
    FormType[FormType["External"] = 3] = "External";
})(FormType || (FormType = {}));
// 根据消息类型获取表单地址
const getDetailUrl = (config, context, type) => {
    if (context.IsBetaForm) {
        return '/home.html#/FormDetail'; // 这个文件的所有跳转home的地址url加上时间戳，都改在auth.ts
    }
    return '/form/defaultsheet/';
};
// 根据appranktype获取回调地址
const getRedirectUrl = (config, context, openType) => {
    const appcode = context.AppCode;
    const schemacode = context.SchemaCode;
    switch (openType) {
        case EntryOpenType.Sheet:
            if (config.from === 'coolapp') {
                return `${getDetailUrl(config, context, FormType.Detail)}?SchemaCode=${context.SchemaCode || config.schemaCode || config.menuCode}&AppRankType=Sheet&cid=${config.cid}`;
            }
            else {
                return `${getDetailUrl(config, context, FormType.Detail)}?SchemaCode=${context.SchemaCode || config.schemaCode || config.menuCode}&AppRankType=Sheet`;
            }
        default:
            if (appcode && appcode !== '' && schemacode && schemacode !== '') {
                return `/home.html#/app?code=${context.AppCode}&id=${context.SchemaCode}`;
            }
            else {
                return '/home.html';
            }
    }
};
const routeActions = [
    {
        condition: (config, context) => {
            return context.ShowLandingPage;
        },
        action: () => {
            return '/pc/landing-page.html';
        }
    },
    {
        condition: (config, context) => {
            return config.messageType === 'QickEntry';
        },
        action: (config, context) => {
            if (context.ShowLandingPage) {
                return '/home.html';
            }
            return '/pc/landing-page.html';
        }
    },
    {
        condition: (config, context) => {
            return config.appId === 'Sys_Workflow';
        },
        action: () => {
            return '/home.html#/MyWork/Todo?gohome=true';
        }
    },
    {
        condition: (config, context) => {
            return config.messageType === 'AppInstall';
        },
        action: (config, context) => {
            let url = '/home.html';
            if (context.SchemaCode) {
                url = `${url}#/app?id=${context.SchemaCode}&code=${config.appId || config.appCode}`;
            }
            return url;
        }
    },
    {
        condition: (config, context) => {
            return config.messageType === 'Share';
        },
        action: (config, context) => {
            const url = getDetailUrl(config, context, FormType.Share);
            const params = [];
            if (config.bizObjectId) {
                params.push(`BizObjectId=${config.bizObjectId}`);
            }
            if (config.workitemId) {
                params.push(`WorkItemID=${config.workitemId}`);
            }
            params.push(`SchemaCode=${config.schemaCode}`);
            params.push(`SharingKey=${config.sharingKey}`);
            params.push(`MessageType=${config.messageType}`);
            return `${url}?${params.join('&')}`;
        }
    },
    {
        condition: (config, context) => {
            return config.messageType === 'InstanceFinished';
        },
        action: (config, context) => {
            const url = getDetailUrl(config, context, FormType.Detail);
            const params = [];
            params.push(`SchemaCode=${config.schemaCode}`);
            params.push(`SheetCode=${config.schemaCode}`);
            params.push(`MessageType=${config.messageType}`);
            if (config.bizObjectId) {
                params.push(`BizObjectId=${config.bizObjectId}`);
            }
            return `${url}?${params.join('&')}`;
        }
    },
    {
        condition: (config, context) => {
            return config.messageType === 'Task' || config.messageType === 'Urgency';
        },
        action: (config, context) => {
            const url = getDetailUrl(config, context, FormType.Detail);
            const params = [];
            params.push(`SchemaCode=${config.schemaCode}`);
            params.push(`SheetCode=${config.schemaCode}`);
            params.push(`MessageType=${config.messageType}`);
            if (config.workitemId) {
                params.push(`WorkItemID=${config.workitemId}`);
            }
            else {
                params.push(`BizObjectId=${config.bizObjectId}`);
            }
            if (config.ddIngPid) {
                params.push(`ddIngPid=${config.ddIngPid}`);
            }
            if (config.ddIngTid) {
                params.push(`ddIngTid=${config.ddIngTid}`);
            }
            if (config.ddIngTid) {
                params.push(`ddIngTid=${config.ddIngTid}`);
            }
            params.push(`ID=${config.schemaCode}`);
            switch (config.taskOpenType) {
                case 'Detail':
                    params.push(`BizObjectId=${config.bizObjectId}`);
                    break;
                case 'Create':
                    break;
                case 'ListView':
                    return `/lightapp/#/${config.appCode}/${config.schemaCode}/sheet`;
            }
            return `${url}?${params.join('&')}`;
        }
    },
    {
        condition: (config, context) => {
            return config.appRankType === EntryOpenType.WorkFlowDesigner;
        },
        action: (config, context) => {
            let url = `/pc/workflow-designer.html?id=${config.schemaCode || config.menuCode}`;
            if (context.IsBetaForm) {
                url += '&isbeta=true';
            }
            return url;
        }
    },
    {
        condition: (config, context) => {
            return config.appRankType === EntryOpenType.Designer;
        },
        action: (config, context) => {
            let url = `/pc/form-designer.html#/form-design?id=${config.schemaCode || config.menuCode}&appcode=${config.appId || config.appCode}`;
            if (context.IsBetaForm) {
                url += '&isbeta=true';
            }
            return url;
        }
    },
    {
        condition: (config, context) => {
            return !config.appRankType && !config.messageType && context.SolutionType !== 1;
        },
        action: (config, context) => {
            return getRedirectUrl(config, context, config.appRankType || EntryOpenType.App);
        }
    },
    {
        condition: (config, context) => {
            return !!config.appRankType || (context.AppCode && context.AppCode !== '' && context.SchemaCode && context.SchemaCode !== '');
        },
        action: (config, context) => {
            return getRedirectUrl(config, context, config.appRankType || EntryOpenType.App);
        }
    },
    {
        condition: (config, context) => {
            return (!config.appRankType && context.SolutionType === 1) || config.appId === 'home' || config.appCode === 'home';
        },
        action: (config, context) => {
            return '/home.html';
        }
    }
];
// 获取钉钉pc端跳转地址
export const getRouterUrl = async (config) => {
    const code = config.schemaCode || config.appCode;
    const appCode = config.appId;
    const ret = await getAppInfo({
        code: code !== 'home' ? code : '',
        solutioncode: config.solutionCode,
        appcode: appCode !== 'home' ? appCode : '',
        source: config.from
    });
    if (ret.Successful) {
        // 只有当Solution状态为安装中时，才会返回SolutionState字段，0表示安装中。
        // 当SolutionState的值为0时，需要把AppCode和SolutionState带到首页，并在首页里做等待和轮询。
        const solutionState = ret.ReturnData.SolutionState;
        if (solutionState === 0) {
            return `/home.html?solutionState=${solutionState}&solutionCode=${config.solutionCode}&appCode=${ret.ReturnData.AppCode}`;
        }
        // 钉钉搭
        if (config.from === 'dingdingda' && ret.ReturnData && ret.ReturnData.ShowTemplateGuide) {
            ret.ReturnData.SchemaCode = 'dingdingda-template-guide-app';
        }
        const context = ret.ReturnData;
        for (const route of routeActions) {
            if (route.condition(config, context)) {
                return route.action(config, context);
            }
        }
    }
    return '/home.html';
};
export const getRouterUrl2 = (config, context) => {
    for (const route of routeActions) {
        if (route.condition(config, context)) {
            return route.action(config, context);
        }
    }
    return '/home.html';
};
