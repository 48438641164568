import { getCookie, clearAuthorization, TokenKey } from '../utils';
// 设置jwt格式token
export const getToken = () => {
    const token = getCookie(TokenKey);
    return token;
};
const proxyAjax = () => {
    if (!XMLHttpRequest) {
        return;
    }
    const nativeAjaxSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function (...args) {
        const oldCb = this.onreadystatechange;
        this.onreadystatechange = (...innerArgs) => {
            if (this.readyState === 4) {
                switch (this.status) {
                    case 401:
                        // token 失效， 清除cookie中token， 跳转到登录页
                        // console.log('401 found');
                        clearAuthorization();
                        window.location.reload();
                        break;
                    default:
                        break;
                }
            }
            oldCb && oldCb.apply(this, innerArgs);
        };
        return nativeAjaxSend.apply(this, args);
    };
};
export default proxyAjax();
